import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default class IndexPage extends React.Component {
  goToContact(e) {
    e.stopPropagation()
    e.preventDefault()

    document.getElementById("contacto").scrollIntoView({ behavior: "smooth" })
  }

  render() {
    return (
      <Layout>
        <SEO title="Defensores Laborales" />

        <div className="section section-header">
          <div className="parallax filter filter-color-black">
            <div className="image header_1"></div>
            <div className="container">
              <div className="content">
                <div className="title-area">
                  <h1 className="title-modern">Defensores Laborales</h1>
                  <h3>Abogados expertos en litigio de materia Laboral</h3>
                  <div className="separator line-separator">♦</div>
                </div>

                <div className="button-get-started">
                  <a
                    onClick={e => this.goToContact(e)}
                    href="#contacto"
                    className="btn btn-white btn-fill btn-lg "
                  >
                    Contáctanos
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container">
            <div className="row">
              <div className="title-area">
                <h2>Nuestros Servicios</h2>
                <div className="separator separator-danger">✻</div>
                <p className="description">
                  Te daremos asesoría y defensa personalizada en materia
                  Laboral. Estamos capacitados para ayudarte en cada una de tus
                  necesidades legales.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-offset-2 col-md-4">
                <div className="info-icon">
                  <div className="icon text-danger">
                    <i className="pe-7s-users"></i>
                  </div>
                  <h3>Empleados</h3>
                  <p className="description">
                    Somos especialistas en la defensa de los derechos laborales
                    de empleados, utilizando todo nuestra experiencia para
                    obtener tu liquidación conforme a la ley.
                  </p>
                </div>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className="info-icon">
                  <div className="icon text-danger">
                    <i className="pe-7s-portfolio"></i>
                  </div>
                  <h3>Empresas</h3>
                  <p className="description">
                    Atendemos todas las cuestiones referentes a las relaciones
                    laborales en las empresas, desde asesoría hasta atención de
                    demandas individuales y colectivas ante la Junta de
                    Conciliación y Arbitraje.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-our-team-freebie">
          <div className="parallax filter filter-color-black">
            <div className="image header_2"></div>
            <div className="container">
              <div className="content">
                <div className="row">
                  <div className="title-area">
                    <h2>Quiénes somos</h2>
                    <div className="separator separator-danger">✻</div>
                    <p className="description">
                      Contamos con más de 15 años de experiencia en materia
                      laboral en el País, atendiendo tanto trabajadores como
                      empresas en cualquier conflicto legal. Estamos muy
                      entusiasmados de acercar este servicio a la comunidad de
                      la Ciudad de Apodaca.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section" id="contacto">
          <div className="container">
            <div className="row">
              <div className="title-area">
                <h2>Contacto</h2>
                <div className="separator separator-danger">✻</div>
                <p className="description">
                  Contáctanos hoy mismo para comenzar a trabajar juntos
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="info-icon">
                  <div className="icon text-danger">
                    <i className="pe-7s-phone"></i>
                  </div>
                  <p className="description">
                    Oficina y Whatsapp: <a href="tel:8115240638">8115 240638</a>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="info-icon">
                  <div className="icon text-danger">
                    <i className="pe-7s-mail"></i>
                  </div>
                  <p className="description">
                    <a
                      href="mailto:defensoreslaboralesmty@outlook.es"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      defensoreslaboralesmty@outlook.es
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="info-icon">
                  <div className="icon text-danger">
                    <i className="pe-7s-map-marker"></i>
                  </div>
                  <p className="description">
                    <a
                      href="https://goo.gl/maps/wmFHjoSgU5X4iJXb6"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Mariano Matamoros 107-B <br />
                      Apodaca Centro
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-big footer-color-black" data-color="black">
          <div class="copyright">
            © {new Date().getFullYear()}
            &nbsp;Defensores Laborales
          </div>
        </footer>
      </Layout>
    )
  }
}
